<template>
  <div class="products-section my-12 max-w-screen overflow-x-hidden">
    <div
      class="container mx-auto relative flex lg:flex-row flex-col pt-12 gap-4"
    >
      <FancyBadge
        class="absolute top-0 start-8"
        :label="productSection.badge"
      ></FancyBadge>
      <h3 class="text-header-xl font-bold flex-1">
        {{ productSection.title }}
      </h3>
      <p v-if="productSection.description" class="flex-1 xl:text-base text-sm">
        {{ productSection.description }}
      </p>
    </div>
    <ScrollCarousel
      :items="productSection.items"
      item-key="id"
      class="container mx-auto mt-8"
      content-class="flex-none w-9/12  md:w-96 md:h-96"
      wrapper-class="w-[calc((100vw+100%)/2)]"
      :style="{
        width: 'calc((100vw + 100%) / 2)',
      }"
    >
      <template #default="{ item, index }">
        <div
          class="h-full w-full secondary-gradient flex flex-col justify-between shadow-sm rounded-md p-4"
        >
          <div class="">
            <label class="text-xl">{{ item.title }}</label>
            <h4
              class="text-xl font-bold primary-gradient text-transparent bg-clip-text"
            >
              {{ item.mention }}
            </h4>
          </div>
          <div class="flex justify-center items-center">
            <NuxtImg
              :src="item.image"
              format="webp"
              class="w-11/12"
              :alt="item.title"
            />
          </div>
          <p class="text-sm">{{ item.description }}</p>

          <button @click="openModal" class="text-primary underline">
            Learn more
          </button>
        </div>
      </template>
    </ScrollCarousel>
  </div>
</template>

<script setup lang="ts">
import { productSection } from "~/lib/constants";
import { useModal } from "vue-final-modal";
import ContactModal from "~/components/global/ContactModal.vue";

const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });

  await modal.open();
};
</script>

<style scoped lang="scss">
/* title-block */

/* Auto layout */
</style>
